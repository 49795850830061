import { useState } from "react";
import Link from "next/link";
import { translate } from "@locales";
import { SubscribeMailchip } from "@models/api/Mailchip";
import Icon from "@components/atoms/icon";
import { QRCodeFooter } from "@components/molecules/download-app-qr";

interface Response {
  message: string;
  icon: JSX.Element | null;
  show: boolean;
}

export function useCommonFooter() {
  const [isEmailActive, setIsEmailActive] = useState<Boolean>(false);
  const [inputValue, setInpuValue] = useState<string>("");
  const [response, setResponse] = useState<Response>({
    message: "",
    icon: null,
    show: false,
  });

  const Suscription = async () => {
    if (!inputValue || inputValue.length < 3) return;

    const res = await SubscribeMailchip(inputValue);

    if (res.status === 500) {
      return setResponse({
        ...response,
        message: translate("landing.email-fail"),
        icon: <Icon.Warning />,
        show: true,
      });
    }
    setResponse({
      ...response,
      message: translate("landing.email-success"),
      icon: <Icon.Submitted />,
      show: true,
    });
  };

  return {
    model: {
      isEmailActive,
      inputValue,
      response,
    },
    operations: {
      setIsEmailActive,
      setInpuValue,
      setResponse,
      Suscription,
    },
  };
}

export default function CommonFooter() {
  const { model, operations } = useCommonFooter();

  return (
    <div className="bg-memod-black">
      <div className="max-w-[1440px] mx-auto">
        <section className="flex flex-col md:flex-row gap-6 pt-8 pb-3 px-4 md:pt-16 md:pb-16 md:px-9 xl:pb-9">
          <div className="flex flex-col md:flex-row md:basis-1/2 gap-6 md:gap-9 md:text-xl md:leading-[26px]">
            <div className="flex flex-col md:basis-1/2 gap-2.5 md:gap-3 text-memod-grey-secondary">
              <h5 className="text-memod-white-base font-semibold">
                {translate("landing.footer-legal")}
              </h5>
              <Link
                href={`/legal/terms-of-service`}
                target="_blank"
              >
                {translate("landing.footer-terms")}
              </Link>
              <Link
                href={`/legal/privacy-policy`}
                target="_blank"
              >
                {translate("landing.footer-privacy")}
              </Link>
              <Link
                href={`/legal/cookies-policy`}
                target="_blank"
              >
                {translate("landing.footer-cookies")}
              </Link>
              <Link
                href={`/legal/copyright-policy`}
                target="_blank"
              >
                {translate("landing.footer-copy")}
              </Link>
            </div>
            <div className="flex flex-col md:basis-1/2 gap-2.5 md:gap-3 text-memod-grey-secondary">
              <h5 className="text-memod-white-base font-semibold">
                {translate("landing.footer-navigate")}
              </h5>
              <Link
                href={"/about-us"}
                target="_blank"
              >
                {translate("landing.footer-about")}
              </Link>
              <a href="https://www.promptmine.ai/">
                Prompt Mine
              </a>
              <Link
                href={`/help/`}
                target="_blank"
              >
                {translate("landing.footer-help")}
              </Link>
              <Link
                href={`/help/contact_us`}
                target="_blank"
              >
                {translate("landing.footer-contact")}
              </Link>
              <a
                href="https://memod.notion.site/We-re-Hiring-b12cb8c438e047a6aaed7205c4efe470"
                target="_blank"
                rel="nofollow"
              >
                {translate("landing.footer-careers")}
              </a>
            </div>
          </div>
          <div className="flex flex-col gap-6 md:basis-1/2">
            <div className="flex flex-col justify-center gap-3 xl:self-end">
              <h5 className="text-memod-white-base font-semibold md:text-xl md:leading-8">
                Subscribe
              </h5>
              <nav className="flex flex-row gap-4">
                <input
                  type="text"
                  onChange={(e) => operations.setInpuValue(e.target.value)}
                  placeholder={translate("landing.email-email")}
                  className="border border-memod-separator rounded-lg bg-memod-button py-2 px-4 md:px-3 md:text-lg text-memod-white-base outline-none grow"
                />
                <button
                  onClick={operations.Suscription}
                  className="flex w-[42px] md:w-[46px] cursor-pointer items-center justify-center rounded-lg bg-memod-green-base text-white"
                >
                  <Icon.Send size={24} />
                </button>
              </nav>
              {model.response.show && (
                <aside className="flex flex-row items-center gap-2 text-sm md:text-base">
                  {model.response.icon}

                  <label className="leading-6 text-memod-white-base">
                    {model.response.message}
                  </label>
                </aside>
              )}
              <div className="lg:w-96">
                <p className="text-sm leading-[1.4] text-base-grey md:leading-4">
                  {translate("landing.footer-spam")}
                </p>
              </div>
            </div>
            <QRCodeFooter className="hidden md:flex xl:hidden" />
          </div>
        </section>
        <footer className="flex flex-col md:flex-row md:items-center gap-7 md:gap-6 px-4 py-5 md:px-9 md:pt-0 md:pb-8 xl:py-10">
          <nav className="flex justify-between text-base-white">
            <Icon.MiniMemod size={50} />
            <a
              rel="nofollow"
              href="https://apps.apple.com/us/app/memod-quick-reads-to-inspire/id1590241623"
              className="flex md:hidden items-center self-start gap-1 p-1 border border-memod-grey-based rounded-lg"
              target="_blank"
            >
              <span className="p-[3px]">
                <Icon.Apple size={11} />
              </span>
              <span className="text-xs leading-none">
                {translate("landing.footer-download")}
              </span>
            </a>
          </nav>
          <p className="text-sm text-memod-grey-secondary">
            &copy; {new Date().getFullYear()}{" "}
            {translate("landing.footer-memod")}
          </p>
          <aside className="flex items-center px-2 py-1 rounded-lg bg-memod-grey-based/60 gap-4 text-base-white self-start md:self-center md:ml-auto">
            <h3 className="text-memod-grey-secondary text-sm">
              {translate("landing.footer-follow")}
            </h3>
            <div className="flex gap-4 h-6">
              <a
                rel="nofollow"
                href="https://www.facebook.com/memodapp1"
                target="_blank"
              >
                <Icon.FacebookSolid className="h-full" />
              </a>
              <a
                rel="nofollow"
                target="_blank"
                href="https://www.instagram.com/memodapp/"
              >
                <Icon.Instagram className="h-full" />
              </a>
              <a
                rel="nofollow"
                target="_blank"
                href="https://twitter.com/memodapp"
              >
                <Icon.Twitter className="h-full" />
              </a>
              <a
                rel="nofollow"
                target="_blank"
                href="https://www.youtube.com/channel/UCvCXWvi3yJQTSxAUJjoehBQ"
              >
                <Icon.Youtube className="h-full" />
              </a>
            </div>
          </aside>
        </footer>
      </div>
    </div>
  );
}
